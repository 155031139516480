<template>
  <div class="header__item header__item--page">
    <div class="row">
      <div
        class="column"
        :class="page.featured_image ? 'small-8 medium-7 xxlarge-8' : 'small-12'"
      >
        <PrismicLink
          v-if="metaType == 'article' && page.category"
          :link="page.category"
          :text="type"
          class="type"
        />
        <span v-else class="type">{{ type }}</span>
        <PrismicLink
          :link="page"
          :text="boldWord($prismic.asText(page.title), searchTerm)"
          class="title"
        />
      </div>
      <div v-if="page.featured_image" class="column small-4 medium-5 xxlarge-4">
        <PrismicLazyImage
          :link="page"
          :image="page.featured_image"
          width="230"
          height="155"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getTitle } from "@/helpers";

import { boldWord } from "@/helpers";

import PrismicLink from "@/components/prismic/PrismicLink";
import PrismicLazyImage from "@/components/prismic/PrismicLazyImage";

export default {
  name: "HeaderSearchPageItem",

  components: {
    PrismicLink,
    PrismicLazyImage
  },

  props: {
    page: {
      type: Object,
      default: null
    },
    searchTerm: {
      type: String,
      default: null
    }
  },

  computed: {
    metaType() {
      return this.page._meta.type;
    },
    type() {
      if (["page", "about"].includes(this.metaType)) {
        return "Page";
      }

      if (this.metaType == "article") {
        if (!this.page.category) return null;

        return getTitle(this.page.category.title);
      }

      if (this.metaType == "studio") {
        return "Studio";
      }

      if (this.metaType == "service_category") {
        return "Service";
      }
    }
  },

  methods: {
    boldWord
  }
};
</script>
