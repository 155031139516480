<template>
  <Expand
    type="search"
    transition="slide-down"
    :class="{ 'is-empty': isFormEmpty }">
    <template v-slot:top>
      <h2 class="ellipsis" v-if="isLoading">Loading Results</h2>
      <h2 v-else-if="isFormEmpty">Search</h2>
      <h2 v-else>{{ numResults }} Results For</h2>

      <form class="form" @submit.prevent ref="form">
        <div class="form__row">
          <div class="form__field">
            <label class="accessible" for="search_term">Search Term</label>
            <input
              id="search_term"
              name="search_term"
              type="text"
              v-debounce="handleSearch"
              v-model="searchTerm"
              placeholder="Enter Search Term"
              autocomplete="off"
              ref="input" />

            <LoadingSpinner v-if="isLoading" />
          </div>
        </div>
      </form>
    </template>

    <template v-slot:content>
      <SearchResults
        :searchTerm="searchTerm"
        :isFormEmpty="isFormEmpty"
        :showSuggestions="isFormEmpty && !isLoading"
        :results="results"
        :isLoading="isLoading"
        @clickSuggestedItem="updateSearchTerm"
        @numResults="updateNumResults" />
    </template>
  </Expand>
</template>

<script>
  import { mapState } from 'vuex'

  import { fetchAlgoliaResults } from '@/services/searchService'

  import Expand from '@/components/header/Expand'
  import SearchResults from '@/components/header/items/SearchResults'
  import LoadingSpinner from '@/components/global/LoadingSpinner'
  import { PredictiveSearch } from '../../../services/AlgoliaService'

  export default {
    name: 'Search',

    components: {
      Expand,
      SearchResults,
      LoadingSpinner,
    },

    data() {
      return {
        searchTerm: '',
        results: [],
        isFormEmpty: true,
        isLoading: false,
        numResults: 0,
        suggestedSearch: null,
      }
    },

    computed: {
      ...mapState({
        activeMenu: state => state.header.activeMenu,
      }),
    },

    watch: {
      searchTerm(newTerm) {
        if (!this.isLoading && newTerm) this.isLoading = true

        this.predictQuery(newTerm)

        if (newTerm.length === 0) this.clearForm()
      },

      activeMenu(newTerm) {
        if (newTerm == 'search') {
          this.$nextTick(() => this.$refs.input.focus())
        }
      },
    },

    methods: {
      clearForm() {
        this.isFormEmpty = true
        this.isLoading = false
        this.suggestedSearch = null
      },

      async predictQuery(query) {
        if (!query) return null

        const predict = new PredictiveSearch({})

        const suggestions = await predict.search(query)

        this.suggestedSearch = suggestions.length ? suggestions[0].query : ''
      },

      async handleSearch() {
        if (!this.searchTerm.length) return null

        const data = await fetchAlgoliaResults(this.searchTerm)

        this.results = data.hits

        this.handleTracking()

        this.isLoading = false
        this.isFormEmpty = false
      },

      updateSearchTerm(evt) {
        this.searchTerm = evt

        this.handleSearch()
      },

      handleTracking() {
        this.$gtag.event('search', {
          search_term: this.searchTerm,
        })

        this.$pinterest.search(this.searchTerm)
        this.$fb.search(this.searchTerm)
      },

      updateNumResults($event) {
        this.numResults = $event
      },
    },
  }
</script>

<style lang="scss">
  .ellipsis:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 500ms infinite;
    content: '\2026'; /* ascii code for the ellipsis character */
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 1.45em;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 1.45em;
    }
  }
</style>
