<template>
  <div class="row gutter-large">
    <!-- Note: I'm using v-show in these instances on purpose for hiding and showing with css so resize works -->
    <div
      v-show="!showSuggestions"
      class="header__search__mobile-buttons column small-12">
      <button
        class="h2"
        :class="{ 'is-active': resultsTypeMobile == 'products' }"
        @click="changeResultsTypeMobile('products')">
        Products
        <span>({{ numProducts + numCategories }})</span>
      </button>

      <button
        class="h2"
        :class="{ 'is-active': resultsTypeMobile == 'pages' }"
        @click="changeResultsTypeMobile('pages')">
        Pages
        <span>({{ numPages }})</span>
      </button>
    </div>

    <div
      v-show="showSuggestions && hasMenu"
      class="column small-12 medium-6 xlarge-4">
      <h3 class="h2">Suggestions</h3>
      <ul v-if="menu" class="split">
        <li
          v-for="(link, i) in menu.links"
          :key="i"
          @click="updateSearchTerm(link.text)">
          <button class="split__item">{{ link.text }}</button>
        </li>
      </ul>
    </div>

    <div v-show="showSuggestions" class="column small-12 medium-6 xlarge-4">
      <template v-if="trendingProduct">
        <h3 class="h2">Trending</h3>
        <HeaderProduct :product="trendingProduct" />
      </template>
    </div>

    <div v-if="!showSuggestions" class="collections column small-12 xlarge-4">
      <div class="row">
        <div class="column small-6 xlarge-12">
          <div class="header__search__result-items">
            <h3 class="h2">
              Collections
              <span v-if="numCategories">({{ numCategories }})</span>
            </h3>
            <template v-if="isLoading">
              <LoadingCard simple v-for="i in 3" :key="i" />
            </template>
            <template v-else>
              <HeaderSearchCategories
                v-if="numCategories"
                :categories="categories"
                :searchTerm="searchTerm"
                index="2" />
              <p v-if="!numCategories">Your search returned 0 collections.</p>
            </template>
          </div>
        </div>
        <div class="column small-6 xlarge-12">
          <IconCallout :type="callout" />
        </div>
      </div>
    </div>

    <div
      v-show="showProductResults"
      class="header__search__products column small-12 medium-6 xlarge-4">
      <div class="header__search__result-items">
        <HeaderSearchCategories
          v-if="numCategories && !isLoading"
          :categories="categories"
          :searchTerm="searchTerm"
          index="2" />
      </div>
      <div class="header__search__result-items">
        <h3 class="h2">
          Products
          <template v-if="productsToShow.length">
            <span class="lowercase">
              ({{ productsToShow.length }} of {{ numProducts }})
            </span>
            <nuxt-link
              class="align-right"
              :to="`/shop/search?q=${encodeURI(searchTerm)}`">
              View All
            </nuxt-link>
          </template>
        </h3>

        <template v-if="isLoading">
          <LoadingCard horizontal v-for="i in 3" :key="i" />
        </template>
        <template v-else>
          <HeaderSearchProduct
            v-for="product in productsToShow"
            :key="product._meta.id"
            :product="product"
            :searchTerm="searchTerm" />
          <p v-if="!products.length">Your search returned 0 products.</p>
        </template>
      </div>
    </div>

    <div
      v-show="showPagesResults"
      class="header__search__pages column small-12 medium-6 xlarge-4">
      <div class="header__search__result-items">
        <h3 class="h2">
          Pages
          <span v-if="pages.length">({{ numPages }})</span>
        </h3>

        <template v-if="isLoading">
          <LoadingCard horizontal v-for="i in 3" :key="i" />
        </template>
        <template v-else>
          <HeaderSearchPageItem
            v-for="page in pages"
            :key="page._meta.id"
            :page="page"
            :searchTerm="searchTerm" />
          <p v-if="!pages.length">Your search returned 0 pages.</p>
        </template>
      </div>
    </div>

    <div v-if="showSuggestions" class="column small-12 xlarge-4">
      <IconCallout :type="callout" />
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { sortByImportance } from '@/services/searchService'

  import { removeOtherLocaleProducts } from '@/helpers'

  import PrismicLink from '@/components/prismic/PrismicLink'

  import HeaderSearchCategories from '@/components/header/items/HeaderSearchCategories'
  import HeaderSearchProduct from '@/components/header/items/HeaderSearchProduct'
  import HeaderProduct from '@/components/header/items/HeaderProduct'
  import HeaderSearchPageItem from '@/components/header/items/HeaderSearchPageItem'
  import IconCallout from '@/components/global/IconCallout'
  import LoadingCard from '@/components/global/LoadingCard'

  export default {
    name: 'SearchResults',

    components: {
      PrismicLink,
      HeaderSearchCategories,
      HeaderSearchProduct,
      HeaderProduct,
      HeaderSearchPageItem,
      IconCallout,
      LoadingCard,
    },

    data() {
      return {
        resultsTypeMobile: 'products',
        products: [],
        categories: [],
        pages: [],
      }
    },

    props: {
      searchTerm: {
        type: String,
        default: '',
      },
      showSuggestions: {
        type: Boolean,
        default: true,
      },
      results: {
        type: Array,
        default: [],
      },
      isLoading: {
        type: Boolean,
      },
      showSuggestions: {
        type: Boolean,
      },
    },

    computed: {
      ...mapState({
        locale: state => state.locale,
        menu: state => state.search.menu,
        trendingProduct: state => state.search.trendingProduct,
        callout: state => state.search.callout,
      }),

      numProducts() {
        return this.products.length
      },

      numCategories() {
        return this.categories.length
      },

      numPages() {
        return this.pages.length
      },

      hasMenu() {
        if (!this.menu || !this.menu.links) return false

        return this.menu.links.length ? true : false
      },

      productsToShow() {
        return this.products.slice(0, 20)
      },

      isMobile() {
        const w =
          window.innerWith ||
          document.documentElement.clientWidth ||
          document.body.clientWidth

        return w <= 480
      },

      showProductResults() {
        if (this.showSuggestions) return false

        if (!this.showSuggestions && this.isMobile)
          return this.resultsTypeMobile == 'products'

        return true
      },

      showPagesResults() {
        if (this.showSuggestions) return false

        if (!this.showSuggestions && this.isMobile)
          return this.resultsTypeMobile == 'pages'

        return true
      },
    },

    watch: {
      searchTerm(newVal) {
        if (!newVal) return null

        this.products = []
        this.categories = []
        this.pages = []
      },

      results(newVal) {
        if (!newVal) return null

        this.splitUpResults()
      },
    },

    methods: {
      ...mapActions(['setQuickShopModalProducts']),

      changeResultsTypeMobile(type) {
        this.resultsTypeMobile = type
      },

      async splitUpResults() {
        if (!this.results.length) return null

        const products = this.results.filter(({ _meta }) => {
          return _meta.type === 'product'
        })

        this.products = await sortByImportance(
          removeOtherLocaleProducts(products, this.locale)
        )

        console.log(this.products);

        this.categories = this.results.filter(({ _meta }) => {
          return [
            'product_type',
            'shop_category',
            'collection',
            'concern',
            'brand',
          ].includes(_meta.type)
        })

        this.pages = this.results.filter(result => {
          return [
            'page',
            'article',
            'studio',
            'service_category',
            'about',
            'custom_landing',
            'brand_landing',
          ].includes(result._meta.type)
        })

        this.setQuickShopModalProducts(this.products)

        const totalResults =
          this.numProducts + this.numCategories + this.numPages

        this.$emit('numResults', totalResults)
      },

      updateSearchTerm(term) {
        this.$emit('clickSuggestedItem', term)
      },
    },

    mounted() {
      this.splitUpResults()
    },
  }
</script>

<style lang="scss" scoped>
  .split__item {
    text-align: left;
  }

  .lowercase {
    text-transform: lowercase;
  }

  .align-right {
    float: right;
  }
</style>
