<template>
  <ul class="large header__search__categories">
    <li v-for="category in categories" :key="`${category._meta.id}`">
      <PrismicLink
        :link="category"
        :text="boldWord($prismic.asText(category.title), searchTerm)" />
    </li>
  </ul>
</template>

<script>
  import { boldWord } from '@/helpers'

  import PrismicLink from '@/components/prismic/PrismicLink'

  export default {
    name: 'HeaderSearchCategories',

    components: {
      PrismicLink,
    },

    props: {
      categories: {
        type: Array,
        default: null,
      },
      searchTerm: {
        type: String,
        default: '',
      },
      index: {
        type: [String, Number],
        default: 1,
      },
    },

    methods: {
      boldWord,
    },
  }
</script>
