<template>
  <div class="loading-card" :class="[{ 'loading-card--horizontal': horizontal }, { 'loading-card--simple': simple }]">
    <div v-if="!simple" class="loading-card__img grey shimmer"></div>

    <div class="loading-card__content">
      <div
        v-if="!simple"
        class="loading-card__text loading-card__text--small grey shimmer"
      ></div>
      <div class="loading-card__text grey shimmer"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingCard",

  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.loading-card {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__img {
    padding-top: 115%;
    margin-bottom: 20px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    padding-top: 22px;
    width: 66%;

    &--small {
      padding-top: 12px;
      width: 50%;
    }

    & + .loading-card__text {
      margin-top: 6px;
    }
  }

  &--horizontal {
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 15px 0 15px;
    padding-bottom: 15px;

    .loading-card__img {
      width: 28%;
      padding-top: 20%;
      margin-bottom: 0px;
    }

    .loading-card__content {
      width: 50%;
      align-items: flex-start;
      justify-content: center;
    }

    .loading-card__text {
      padding-top: 15px;
      width: 100%;

      &--small {
        padding-top: 12px;
        width: 66%;
      }

      & + .loading-card__text {
        margin-top: 5px;
      }
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid #d8d8d8;
    }
  }

  &--simple {
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 35px;
    }

    .loading-card__text {
      padding-top: 19px;
    }

    .loading-card__content {
      align-items: flex-start;
    }
  }
}

.grey {
  background-color: #f0f0f0;
}

.shimmer {
  animation: shimmer 2.5s infinite;
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(#eee, #eee),
    radial-gradient(38px circle at 19px 19px, #f0f0f0 50%, transparent 51%),
    linear-gradient(#eee, #eee);
  background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
  background-size: 2000px 100%;
}

@keyframes shimmer {
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}
</style>
