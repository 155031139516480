import { Algolia } from "./AlgoliaService";

import { getTitle } from "@/helpers";
import { fetchSearchResultsByTerm } from "@/prismic/queries/global";
import { handlePrismicQueries } from "@/services/shopLandingService";

const SEARCH_DOCUMENT_TYPES = [
  "brand",
  "product",
  "concern",
  "collection",
  "product_type",
  "shop_category",
  "page",
  "about",
  "studio",
  "article",
  "service_category",
  "custom_landing",
  "brand_landing"
];

export const fetchAlgoliaResults = async (query) => {
  return await Algolia.search(query, { hitsPerPage: 900 })
}

export const fetchResults = async query => {
  try {
    const data = await handlePrismicQueries(
      fetchSearchResultsByTerm,
      { term: query, numResults: 5000 },
      "search"
    );

    const { edges } = data;

    if (!edges.length) return [];

    const sortedResults = filterResults(edges).then(results =>
      sortByMatch(results, query)
    );

    return sortedResults;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const filterResults = async hits => {
  return await hits
    .filter(hit => SEARCH_DOCUMENT_TYPES.includes(hit._meta.type));
};

export const sortByImportance = results => {
  return results.sort((a, b) => {
    const aImportance = a.importance ?? 0;
    const bImportance = b.importance ?? 0;

    if (aImportance < bImportance) return 1;

    if (aImportance > bImportance) return -1;

    return 0;
  });
};

export const sortByMatch = (results, query) => {
  const queryString = query.toLowerCase();
  const priorityItems = findHighPriorityItems(results, queryString);
  const otherItems = findLowPriorityItems(results, queryString, priorityItems);

  return [...priorityItems, ...otherItems];
};

const findHighPriorityItems = (results, query) => {
  return findResultsBasedOnMatch(results, query, true)
}

const findLowPriorityItems = (results, query, arrayToSkip) => {
  return findResultsBasedOnMatch(results, query, false, arrayToSkip)
}

export const findResultsBasedOnMatch = (
  results,
  query,
  isPriority = true,
  arrayToSkip = null
) => {
  return results.filter(({ title, brand, _meta }) => {
    if (_meta.type !== "product") {
      return stringIncludesQuery(title, query, isPriority);
    }

    if (brand) return checkBrandAndTitle(title, brand, query, isPriority);

    return stringIncludesQuery(title, query, isPriority);
  });
};

const checkBrandAndTitle = (title, brand, query, isPriority) => {
  // if the brand name or product title does not include the search term
  // these are lower priority items
  if (!isPriority) {
    return (
      stringIncludesQuery(title, query, false) &&
      stringIncludesQuery(brand.title, query, false)
    );
  }

  // if the brand name / product title matches the search term
  // these are higher priority items
  return (
    stringIncludesQuery(title, query, true) ||
    stringIncludesQuery(brand.title, query, true)
  );
};


export const stringIncludesQuery = (haystack, needle, checkForMatch = true) => {
  if (!haystack) return false;

  // return results where there's no match in the haystack
  // returns lower priority items
  if (!checkForMatch) {
    return !getTitle(haystack).toLowerCase()
      .includes(needle.toLowerCase());
  }

  // return results where there's a direct match in the haystack
  // i.e. haystack contains the needle -- 
  // returns high priority items
  return getTitle(haystack).toLowerCase()
    .includes(needle.toLowerCase());
};

const entryExistsInArray = (arrayToCheck, itemId) => {
  return arrayToCheck.find(({ _meta }) => _meta.id === itemId);
};
