<template>
  <div v-if="product" class="header__product product" :class="{ 'header__product--large' : isLarge }">
    <ProductTag v-if="isLarge && showFeaturedCollectionsMenu" text="Featured" />
    <div class="row">
      <div class="column" :class="column1Classes">
        <ShopifyLazyImage
          v-if="featuredImage"
          :image="featuredImage"
          :link="product"
          :width="isLarge ? 535 : 200"
          :height="isLarge ? 600 : 150"
        />
      </div>
      <div class="column" :class="column2Classes">
        <BrandLink v-if="(product.brand && !isMakeup) || isBundle" :brand="product.brand" :isBundle="isBundle" />
        <PrismicLink
          class="title"
          :link="product"
          :text="$prismic.asText(product.title)"
        />
        <template v-if="isLarge">
          <prismic-rich-text :field="product.featured_text" />
          <ArrowLink :link="product" text="Details" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import productMixin from '@/mixins/productMixin';

  import ProductTag from '@/components/shop/ProductTag';
  import ShopifyLazyImage from '@/components/shop/ShopifyLazyImage';
  import BrandLink from '@/components/shop/BrandLink';
  import PrismicLink from '@/components/prismic/PrismicLink';
  import ArrowLink from '@/components/global/ArrowLink';

  export default {
    name: 'HeaderProduct',

    mixins: [ productMixin ],

    components: {
      ProductTag,
      ShopifyLazyImage,
      BrandLink,
      PrismicLink,
      ArrowLink
    },

    props: {
      product: {
        type: Object,
        default: null
      },
      showFeaturedCollectionsMenu: {
        type: Boolean,
        default: false
      },
      isMakeup: {
        type: Boolean,
        default: false
      },
      isLarge: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      column1Classes() {
        if(this.isLarge) {
          if(this.showFeaturedCollectionsMenu) {
            return 'small-6 xxlarge-5';
          } else {
            return 'small-6';
          }
        } else {
          return 'small-5 xxlarge-4';
        }
      },
      column2Classes() {
        if(this.isLarge) {
          if(this.showFeaturedCollectionsMenu) {
            return 'small-6 xxlarge-7';
          } else {
            return 'small-6';
          }
        } else {
          return 'small-7 xxlarge-8';
        }
      }
    },

    mounted() {
      this.fetchAdditionalShopifyProductInfo();
    }
  }
</script>
