<template>
  <div v-if="product" class="header__product product">
    <div class="row">
      <div class="column small-9 medium-8 xxlarge-9">
        <BrandLink v-if="product.brand || isBundle" :brand="product.brand" :isBundle="isBundle" />
        <PrismicLink
          class="title"
          :link="product"
          :text="boldWord($prismic.asText(product.title), searchTerm)"
        />
      </div>
      <div class="column small-3 medium-4 xxlarge-3">
        <ShopifyLazyImage
          v-if="featuredImage"
          :image="featuredImage"
          :link="product"
          width="100"
          height="135"
        />
      </div>
    </div>
    <!-- <QuickShopButton :price="defaultPrice" :salePrice="defaultSalePrice" :activeProductUid="product._meta.uid" type="search" :modalTitle="searchTerm" /> -->
  </div>
</template>

<script>
  import productMixin from '@/mixins/productMixin';

  import { boldWord } from '@/helpers';

  import BrandLink from '@/components/shop/BrandLink';
  import PrismicLink from '@/components/prismic/PrismicLink';
  import ShopifyLazyImage from '@/components/shop/ShopifyLazyImage';
  import QuickShopButton from '@/components/shop/QuickShopButton';

  export default {
    name: 'HeaderSearchProduct',

    mixins: [ productMixin ],

    components: {
      BrandLink,
      PrismicLink,
      ShopifyLazyImage,
      QuickShopButton
    },

    props: {
      product: {
        type: Object,
        default: null
      },
      searchTerm: {
        type: String,
        default: null
      }
    },

    methods: {
      boldWord
    }
  }
</script>
